main {
  display: grid;
  place-items: center;
}

section {
  width: 100%;
  padding: var(--space-l);
}

section > h2 {
  font-size: var(--fs-4xl);
  text-align: center;
  margin-top: 0;
  margin-block: var(--space-xl);
}

.card-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35ch, 1fr));
  place-items: center;
  gap: var(--space-s);
}

.card-container.art {
  grid-auto-flow: dense;
}

.card-container > * {
  width: 100%;
  height: 100%;
}

.card-container.art > * {
  width: auto;
  height: auto!important;
}

.card-container.art > * img{
  width: 100%;
  object-position: top;
  max-height: 40svh;
  overflow: hidden;
}

.card-container a {
  max-width: unset!important;
}

@media screen and (max-width: 450px) {
  section {
    padding: 0;
  }
}