.navbar {
    --nav_background: transparent;
    display: flex;
    position: sticky;
    top: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-inline: auto;
    height: var(--header-height);
    z-index: 999;
    background-color: var(--nav_background);
    transition: all 500ms;
    overflow: hidden;
    padding: var(--space-2xs);
}

.navbar.active {
    --nav_background: hsl(var(--background), 0.45);
    top: 1rem;
    width: 580px;
    align-items: center;
    backdrop-filter: blur(8px);
    box-shadow:
        0.125em 0.125em 0.5em hsl(var(--shadow), 0.25);
    border-radius: 100vw;
}


.logo {
    height: calc(var(--fs-l) + 32px);
    width: 85px;
    margin: 0 var(--space-s) 0 var(--space-2xs);
    background-color: hsl(var(--text));
    mask-image: url(../assets/images/other/logo.png);
    mask-size: contain;
    mask-repeat: no-repeat;
    transition: all 250ms var(--cubic-bezier);
}

.navbar.active .logo {
    width: 0px;
    height: 0px;
    opacity: 0;
}

.nav_link_list {
    height: 100%;
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: var(--space-xs);
    padding-right: 1em;
}

.nav_link {
    display: grid;
    place-items: center;
    color: hsl(var(--text));
    font-size: var(--fs-l);
    font-weight: 900;
    text-shadow: 0 0 0.75em hsl(var(--background), .25);
    text-decoration: none;
    user-select: none;
    padding: var(--space-2xs);
    transition: all 500ms var(--cubic-bezier);
}

.nav_link:is(:hover, :focus-visible) {
    color: hsl(var(--primary));
    padding: var(--space-m);
}

.nav_link:is(:active) {
    color: hsl(var(--accent));
}

.nav_link > svg {
    display: none;
}

@media screen and (max-width: 600px) {
    .navbar.active {
        width: max-content;
    }

    .nav_link > span {
        display: none;
    }

    .nav_link > svg {
        display: block;
    }
}