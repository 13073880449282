:root {
	/* Font sizes */
	--fs-xs: clamp(0.7813rem, 0.7747rem + 0.0326vi, 0.8rem);
	--fs-s: clamp(0.9375rem, 0.9158rem + 0.1087vi, 1rem);
	--fs-m: clamp(1.125rem, 1.0815rem + 0.2174vi, 1.25rem);
	--fs-l: clamp(1.35rem, 1.2761rem + 0.3696vi, 1.5625rem);
	--fs-xl: clamp(1.62rem, 1.5041rem + 0.5793vi, 1.9531rem);
	--fs-2xl: clamp(1.944rem, 1.771rem + 0.8651vi, 2.4414rem);
	--fs-3xl: clamp(2.3328rem, 2.0827rem + 1.2504vi, 3.0518rem);
	--fs-4xl: clamp(2.7994rem, 2.4462rem + 1.7658vi, 3.8147rem);
	--fs-5xl: clamp(4.8373rem, 3.9283rem + 4.5448vi, 7.4506rem);
	--lh-s: 1;
	--lh-m: 1.1;
	--lh-l: 1.4;

	/* Spacing */
	--space-3xs: clamp(0.3125rem, 0.3125rem + 0vi, 0.3125rem);
	--space-2xs: clamp(0.5625rem, 0.5408rem + 0.1087vi, 0.625rem);
	--space-xs: clamp(0.875rem, 0.8533rem + 0.1087vi, 0.9375rem);
	--space-s: clamp(1.125rem, 1.0815rem + 0.2174vi, 1.25rem);
	--space-m: clamp(1.6875rem, 1.6223rem + 0.3261vi, 1.875rem);
	--space-l: clamp(2.25rem, 2.163rem + 0.4348vi, 2.5rem);
	--space-xl: clamp(3.375rem, 3.2446rem + 0.6522vi, 3.75rem);
	--space-2xl: clamp(4.5rem, 4.3261rem + 0.8696vi, 5rem);
	--space-3xl: clamp(6.75rem, 6.4891rem + 1.3043vi, 7.5rem);

	/* Border Radius */
	--round-s: .25rem;
	--round-m: .625rem;
	--round-l: 1rem;
	--round-full: 100vmax;

	/* Colors */
	--text: 228, 33%, 14%;
	--background: 270, 25%, 98%;
	--primary: 230, 19%, 38%;
	--secondary: 289, 25%, 64%;
	--tertiary: 278, 23%, 75%;
	--accent: 45, 96%, 65%;
	--shadow: 23, 19%, 5%;
	--glow: 48, 65%, 100%;
	--test: 255, 100%, 100%;

	/* Cubic Bezier */
	--cubic-bezier: cubic-bezier(0.075, 0.82, 0.165, 1);

	--header-height: 80px
}

@media (prefers-color-scheme: dark) {
	:root {
		--text: 270, 25%, 98%;
		--background: 228, 33%, 14%;
		--primary: 289, 25%, 64%;
		--secondary: 278, 23%, 75%;
		--tertiary: 230, 19%, 38%;
		--accent: 45, 96%, 65%;
	}
}

html {
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	font-synthesis: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	background-color: hsl(var(--background));
	color: hsl(var(--text));
	scroll-behavior: smooth;
	/* scroll-padding: calc(var(--header-height)); */
}