.btn {
    --type: transparent;
    --fill: transparent;
    --filled-color: hsl(var(--background));
    display: flex;
    gap: var(--space-2xs);
    padding: var(--space-xs) var(--space-s);
    justify-content: center;

    font-size: var(--fs-m);
    line-height: var(--lh-s);
    text-decoration: none;

    border: .2em solid var(--type);
    border-radius: var(--round-s);

    background-color: var(--fill);
    color: var(--type);
    cursor: pointer;
    max-width: max-content;

    transition: all 500ms var(--cubic-bezier);
}

.btn svg {
    height: var(--fs-m);
}

.btn:focus {
    outline: none;
}

.btn.filled:is(:hover, :focus-visible) {
    box-shadow:
        0 0 0 .2em currentColor,
        0 0 0 .4em var(--type);
}

.btn:not(.filled):is(:hover, :focus-visible) {
    box-shadow:
        0 0 0 .2em hsl(var(--background)),
        0 0 0 .4em var(--type);
}

.primary {
    --type: hsl(var(--primary));
}

.secondary {
    --type: hsl(var(--secondary));
}

.accent {
    --type: hsl(var(--accent));
}

.tertiary {
    --type: hsl(var(--tertiary));
}

.primary, .secondary, .accent  {
    --filled-color: hsl(var(--text));
}

.filled {
    --fill: var(--type);
    color: var(--filled-color)
}
