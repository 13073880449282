.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--space-m);
    background-color: hsl(var(--glow), 0.025);
    box-shadow:
        0.125em 0.125em 0.5em hsl(var(--shadow), 0.25);
    border-radius: var(--round-l);
    position: relative;
    /* max-height: 40dvh; */
    max-width: 720px;
}

.image {
    object-fit: cover;
    width: 100%;
    border-radius: var(--round-m);
    background-position: center;
    aspect-ratio: 1/1;
    box-shadow:
        0 0 0.5rem 0.25rem hsl(var(--shadow), 0.1),
        0 0 4rem 0.5rem hsl(var(--shadow), 0.05);
}

.title {
    font-size: var(--fs-l);
}

.subtitle {
    font-size: var(--fs-xs);
    font-weight: 900;
}

.subtitle>a {
    color: hsl(var(--tertiary));
}

.body {
    font-size: var(--fs-s);
    line-height: var(--lh-s);
}

.btn_container {
    width: 100%;
    display: flex;
    gap: var(--space-xs);
    margin-top: var(--space-xs);
}

.btn_container > * {
    padding: var(--space-3xs);
    width: 4ch;
}

.indicator {
    position: absolute;
    right: 0;
    top: 0;
    background-color: hsl(var(--primary));
    color: hsl(var(--text));
    border-radius: var(--round-full);
    aspect-ratio: 1/1;
    display: grid;
    place-items: center;
    padding: var(--space-xs);
    transform: translate(25%, -25%);
    font-size: var(--fs-l);
    font-weight: 900;
    user-select: none;
    box-shadow:
        0.1em 0.1em 0.25em hsl(var(--shadow), 0.25);
}