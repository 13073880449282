.card {
    aspect-ratio: 1/1.25;
    position: relative;
    max-height: 40svh;
    border-radius: var(--round-m);
    overflow: hidden;
    box-shadow:
        0.125em 0.125em 0.25em hsl(var(--shadow), 0.25);
}

.card .image {
    min-height: 100%;
    object-fit: cover;
    object-position: center;
    scale: 1;
    transition: all 750ms var(--cubic-bezier);
}

.card .background {
    display: none;
}

.card_bottom {
    position: absolute;
    bottom: 0;
    height: 6rem;
    text-wrap: balance;
    width: 100%;
    border-radius: var(--round-m) var(--round-m) 0 0;
    background-color: hsl(var(--secondary));
    color: hsl(var(--background));
    display: grid;
    place-content: center;
    padding: var(--space-m) var(--space-xl);
    gap: var(--space-m);
    backdrop-filter: blur(0px) opacity(0);
    align-content: center;
    transition: all 500ms var(--cubic-bezier), backdrop-filter 750ms var(--cubic-bezier);
}

.text {
    display: grid;
    gap: var(--space-3xs);
    user-select: none;
}

.text>* {
    margin: 0;
    width: 100%;
    text-align: left;
}

.text .title {
    font-size: var(--fs-m);
}

.subtitle_link {
    text-decoration: none;
    font-size: var(--fs-m);
    color: hsl(var(--tertiary));
}

.subtitle_link:is(:hover, :focus) {
    color: hsl(var(--secondary));
}

.text .date {
    font-size: var(--fs-xs);
}

.buttons {
    --_button-size: 4ch;
    width: 100%;
    justify-content: center;
    grid-template-columns: var(--_button-size) var(--_button-size);
    gap: var(--space-xs);
    color: hsl(var(--text));
    font-size: var(--fs-l);
}

.buttons>* {
    aspect-ratio: 1/1;
    width: var(--_button-size);
    background-color: hsl(var(--tertiary));
    text-decoration: none;
    border-radius: var(--round-m);
    display: grid;
    place-items: center;
}

.buttons>.unavailable,
.buttons>.unavailable:focus {
    background-color: hsl(var(--tertiary), .3);
    color: hsl(var(--text), .4);
    cursor: not-allowed;
}

.buttons>.unavailable:hover {
    background-color: hsl(var(--tertiary), .1);
    color: hsl(var(--text), .1);
    user-select: none;
}

.buttons>*:is(:hover, :focus) {
    background-color: hsl(var(--secondary));
}

@media screen and (min-width: 451px) {
    .featured {
        grid-row: span 2;
        grid-column: span 2;
        max-width: 100%;
        max-height: 80svh;
        background-color: hsl(var(--text));
    }

    .featured .indicator {
        position: absolute;
        top: 0;
        width: 100%;
        font-size: var(--fs-4xl);
        padding-block: var(--space-s) var(--space-3xl);
        color: hsl(var(--background));
        text-align: center;
        backdrop-filter: blur(500px) opacity(1);
        mask-image: linear-gradient(to bottom,
                hsl(var(--background)) 0%,
                hsl(var(--background)) 60%,
                hsl(var(--background), 0) 100%);
    }

    .featured .title {
        font-size: var(--fs-2xl);
    }

    .featured .subtitle {
        font-size: var(--fs-m);
    }

    .featured .card_bottom {
        height: fit-content;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        gap: var(--space-l);
        padding: var(--space-3xl) var(--space-m) var(--space-l);
        background-color: hsl(var(--text), .35);
        backdrop-filter: blur(500px) opacity(1);
        mask-image: linear-gradient(to top,
                hsl(var(--background)) 0%,
                hsl(var(--background)) 70%,
                hsl(var(--background), 0) 100%);
    }

    .featured .card_bottom_hover {
        display: grid;
        place-self: center;
    }

    .featured .buttons {
        font-size: var(--fs-xl);
        width: auto;
        height: min-content;
        display: flex;
    }

    .featured>.buttons>* {
        max-height: var(--_button-size);
    }

    .featured:is(:hover, :focus-within) .image {
        opacity: 0.6;
        scale: 1.1;
        filter: blur(8px);
    }
}

@media screen and (min-width: 451px) {
    .card:not(.featured):not(:hover, :focus-within) .card_bottom_hover {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .card:not(.featured):is(:hover, :focus-within) .card_bottom {
        height: 100%;
        background-color: hsl(var(--text), .35);
        backdrop-filter: blur(500px) opacity(1);
    }

    .card:is(:hover, :focus-within) .image {
        height: 100%;
        scale: 1.5;
    }

    .card:not(.featured):is(:hover, :focus-within) .card_bottom_hover {
        display: grid;
    }
}

@media screen and (max-width: 450px) {

    :has(> .card) {
        gap: 0;
    }

    .card {
        aspect-ratio: unset;
        width: 100%;
        display: flex;
        align-items: center;
        height: auto;
        max-width: unset;
        background-color: hsl(var(--background), .45);
        box-shadow: none;
        border-radius: 0;
        outline: 1px solid hsl(var(--glow), .05);
    }

    .text {
        color: hsl(var(--text));
    }

    .card .background {
        display: unset;
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        scale: 2;
        z-index: -1;
        filter: blur(500px);
    }

    .card .image {
        width: calc(50% - 2 * var(--space-s));
        min-height: unset !important;
        margin-inline: var(--space-s);
        margin-block: var(--space-xs);
        border-radius: var(--round-m);
        box-shadow:
            0.125em 0.125em 0.25em hsl(var(--shadow), 0.25);
    }

    .card_bottom {
        position: relative;
        height: fit-content;
        width: 50%;
        padding: var(--space-s) var(--space-s) var(--space-m) var(--space-s);
        background-color: transparent;
        place-content: unset;
    }

    .card_bottom_hover {
        display: grid;
    }

    .buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: unset;
    }
}