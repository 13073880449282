header {
    position: relative;
    display: grid;
    place-items: center;
    min-height: calc(100svh - var(--header-height));
    width: 100vw;
    background: linear-gradient(to top,
            hsl(var(--background)) 0%,
            hsl(var(--background), 0) 20%,
            hsl(var(--background), 0) 80%,
            hsl(var(--background)) 100%);
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: -1;
    opacity: 0.2;
}

.background>img {
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    object-position: center;
    filter: blur(12px);
}

.content {
    width: min(80ch, 90%);
    display: grid;
}

.content > *:not(a) {
    text-shadow: 0 0 0.25em hsl(var(--shadow), 0.25);
}

.title {
    margin-top: 0;
    margin-left: -0.05em;
    font-size: calc(1.618 * var(--fs-5xl));
    color: hsl(var(--text));
    user-select: none;
}

.subtitle {
    font-size: var(--fs-m);
    font-weight: 900;
}

.body {
    font-size: var(--fs-l);
}

.button {
    text-decoration: none;
    margin-top: var(--space-3xl);
    margin-inline: auto;
    font-weight: 900;
    box-shadow:
        0.125em 0.125em 0.25em hsl(var(--text), 0.1);
    color: hsl(var(--background));
    background-color: hsl(var(--primary));
    padding: var(--space-xs);
    border-radius: var(--round-m);
}

.button:is(:hover, :focus) {
    background-color: hsl(var(--text));
}

@media screen and (max-width: 1300px) {
    .title {
        font-size: calc(2 * var(--fs-5xl));
    }
}

@media screen and (max-width: 600px) {
    .title {
        font-size: var(--fs-5xl);
    }

    .subtitle {
        font-size: var(--fs-s);
    }

    .body {
        font-size: var(--fs-m);
    }
}